const serviceData = [
  {
    id: 1,
    title: "Terraform",
    icon: "ri-map-pin-2-line",
    desc: "Terraform is an Infrastructure as Code (IaC) tool developed by HashiCorp. It allows you to define and provision infrastructure declaratively, using a simple and readable language called HashiCorp Configuration Language (HCL). One of the main advantages of Terraform is that it is provider agnostic, which means that you can use it to manage resources on a variety of cloud providers such as AWS, Azure, Google Cloud Platform and others. This allows you to take a consistent infrastructure-as-code approach no matter which provider you're working with.",
  },

  {
    id: 2,
    title: "Kubernetes",
    icon: "ri-community-line",
    desc: "Kubernetes is an open source platform for automating, scaling, and managing containerized applications. It provides a robust and flexible environment for deploying, scaling and orchestrating containers across server clusters. With Kubernetes, you can take full advantage of the benefits of containers such as application isolation, fast and consistent deployments, and the ability to work with multiple container technologies such as Docker, rkt, and more. In short, Kubernetes is a powerful container orchestration platform designed to simplify the management and scalability of applications in production environments. It offers advanced features and flexibility to help development and operations teams efficiently and reliably deploy and manage applications.",
  },

  {
    id: 3,
    title: "Docker",
    icon: "ri-roadster-line",
    desc: "Docker is a software container technology that has revolutionized the way companies develop, package and deploy applications. It provides a standardized, portable approach to packaging an application and its dependencies in a lightweight, isolated container. In enterprises, Docker brings a number of significant benefits. First, it simplifies the application development process, allowing developers to create consistent, replicable, and isolated development environments. This means that developers can work in their own development environments without worrying about compatibility issues between different machines or configurations.Another advantage of Docker is scalability. Docker allows applications to scale quickly on demand by replicating containers across multiple nodes. This makes it possible for companies to manage intensive workloads efficiently and economically.",
  },

  {
    id: 4,
    title: "AWS",
    icon: "ri-timer-flash-line",
    desc: "Amazon Web Services (AWS) offers a wide range of services that are extremely useful for implementing and improving DevOps practices. These services are designed to help development and operations teams automate, deploy and manage applications efficiently and scalably in the cloud. Here are some of the top AWS services for DevOps: Amazon Elastic Compute Cloud (EC2), AWS Elastic Beanstalk, AWS Lambda, Amazon Simple Storage Service (S3), AWS CodePipeline and others.",
  },

  {
    id: 5,
    title: "GCP",
    icon: "ri-map-pin-line",
    desc: "Google Cloud Platform (GCP) offers a number of services that are highly relevant to the efficient implementation of DevOps practices. These services are designed to help development and operations teams automate, deploy, and manage applications scalably in the cloud. Here are some of the top GCP services for DevOps: Google Kubernetes Engine (GKE), Cloud Build, Cloud Storage and others.",
  },

  {
    id: 6,
    title: "Azure",
    icon: "ri-flight-takeoff-line",
    desc: "Microsoft Azure offers a wide range of services that are essential for successfully implementing DevOps practices. These services are designed to help development and operations teams efficiently automate, deploy, and manage applications in the cloud. Here are some of the top Azure services for DevOps: Azure App Service, Azure Container Instances (ACI), Azure Kubernetes Service (AKS), Azure Monitor, Azure Log Analytics and others.",
  },
];

export default serviceData;
