import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
// import aboutImg from "../../assets/all-images/cars-img/bmw-offer.png";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title">Welcome to Matriz Studios</h2>
              <p className="section__description">
                Matriz Studios is an organization dedicated to the implementation of DevOps practices and culture in its software development projects. We seek the automation of development, testing, deployment and infrastructure processes. We believe that automation reduces errors, increases productivity, and enables continuous, reliable software delivery. At Matriz Studios, we work in partnership with our clients to help them adopt DevOps practices and processes, driving their digital transformation, increasing delivery speed and improving the reliability of their systems. We are committed to providing high-quality, value-added software solutions through an agile, customer-centric approach.
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Knowledge in different clouds.
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Best practices for creating your environment.
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Application improvements
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Consultancy 
                </p>
              </div>
            </div>
          </Col>

          {/* <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col> */}
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
