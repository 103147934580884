import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  {
    path: "#",
    display: "Privacy Policy",
  },
  {
    path: "/blogs",
    display: "Modules",
  },

  {
    path: "/contact",
    display: "Contact",
  },
];

const Links = [
  {
    path: "https://kubernetes.io/pt-br/docs/home/",
    display: "Kubernetes",
  },

  {
    path: "https://konghq.com/",
    display: "Kong",
  },
  {
    path: "https://argoproj.github.io/cd/",
    display: "ArgoCD",
  },

  {
    path: "https://developer.hashicorp.com/terraform/language",
    display: "Terraform",
  },
  {
    path: "https://www.docker.com/products/docker-hub/",
    display: "Docker",
  },
  {
    path: "https://www.geeksforgeeks.org/postgresql-psql-commands/",
    display: "Postgresql",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                  <i class="x-entypo-laptop"></i>
                  <span>
                    Matriz <br /> Studios
                  </span>
                </Link>
              </h1>
            </div>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Documentation is always good, isn't it!</h5>
              <ListGroup>
                {Links.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>


          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">São josé dos Campos - SP - Brazil</p>
              {/* <p className="office__info">Phone: +0995345875365</p> */}

              <p className="office__info">Email: matrizstudioss@gmail.com</p>

              <p className="office__info">Office Time: 06am - 09am <br />18pm - 20pm</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Developed by Matriz Studios. All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
