// import images from all-images/blog-img directory
import img01 from "../all-images/blog-img/image1.1.jpeg";
import img02 from "../all-images/blog-img/image1.2.jpeg";
import img03 from "../all-images/blog-img/image1.3.jpeg";

const blogData = [
  {
    id: 1,
    title: "Modules AWS - VPC",
    author: "Matriz Studios",
    date: "19 MAI, 2022",
    // time: "9pm",
    imgUrl: img01,
    description:
      "Creating a VPC (Virtual Private Cloud) using Terraform is a simple and efficient process. Terraform is an infrastructure-as-code tool that allows you to declaratively describe and provision infrastructure resources. To create a VPC using Terraform, you need to define a configuration file called main.tf (or whatever name you prefer). In this file, you will specify the VPC resources such as the network, subnets, routing tables, gateways, and other necessary settings.First, you'll define the VPC itself, providing details such as the IP address block and region it will be created in. You can then define the subnets within the VPC, specifying the IP address block for each subnet and which Availability Zone they belong to.Then you can configure the routing tables to route traffic between subnets and to the Internet if needed. In addition, you can add gateways, such as the Internet Gateway, to allow your VPC to connect to the Internet.You can then define security groups to control traffic in and out of your VPC and allow access to VPC resources only from trusted sources.Finally, you will run the terraform apply command in the directory where the configuration file is located. Terraform will read the file, schedule the necessary changes, and then create the VPC based on the definitions provided.Using Terraform to create a VPC has significant benefits, such as the ability to version your infrastructure, automate the provisioning and modification of resources, and provide accurate and up-to-date documentation of your infrastructure.In short, Terraform simplifies and automates the process of creating a VPC, allowing you to declaratively define and provision infrastructure resources. With Terraform, you can build and manage your VPC in an efficient, repeatable, and scalable way.",
    quote:
      "Contact us to provision it in your environment",
  },

  {
    id: 2,
    title: "Modules AWS - Amplify",
    author: "Matriz Studios",
    date: "19 MAI, 2022",
    imgUrl: img02,
    description:
      "Amplify is an AWS (Amazon Web Services) service that simplifies the process of developing, deploying, and managing web and mobile applications. It provides a quick and easy way to build apps with features like user authentication, data storage, push notifications, and more. With Amplify, you can start developing your app quickly by providing a prebuilt framework and ready-to-use components. It supports multiple platforms such as Android, iOS, and the web, allowing you to build native or cross-platform apps.One of the main features of Amplify is the ease of integration with other AWS services. For example, you can use Amazon Cognito to authenticate users, Amazon S3 to store files, and Amazon DynamoDB to store application data. Amplify also supports integrations with third-party services like Google Maps to add additional features to your app.Amplify also offers CI/CD (Continuous Integration and Continuous Delivery) capabilities, allowing you to automate the process of building, testing and deploying your application. With the Amplify Console, you can set up continuous delivery pipelines to automatically deploy your app when code changes.In addition, Amplify provides features for analyzing and monitoring your application's performance. You can track key metrics such as response time, errors, and resource usage to identify potential issues and optimize application performance.In summary, Amplify is a powerful platform for web and mobile application development and deployment. It simplifies the process of building applications, offers easy integration with other AWS services, and provides CI/CD and analytics capabilities. With Amplify, you can accelerate your app development and provide an exceptional experience for your end users.",
    quote:
    "Contact us to provision it in your environment",
  },

  {
    id: 3,
    title: "HELM",
    author: "Matriz Studios",
    date: "19 MAI, 2022",
    imgUrl: img03,
    description:
      " Helm is a package management tool for Kubernetes that simplifies the process of deploying and managing applications on Kubernetes clusters. It allows you to easily and consistently define, install, and update Kubernetes applications.Helm uses a file structure called a chart to describe and package the Kubernetes resources needed to deploy an application. A chart includes YAML files that describe the Kubernetes services, pods, deployments, configmaps, and other objects needed to run the application.Charts can be customized to meet the specific needs of each application. They allow the parameterization of values, which can be configured according to the environment in which the application will be deployed, facilitating reuse and flexible configuration of resources.By using Helm, you can easily install and manage applications on Kubernetes clusters. Through the helm install command, you can deploy a chart on a cluster with all its dependencies and configurations. Helm also offers advanced features such as the ability to manage multiple versions of an application, enabling quick and secure deployment and rollback. In addition, it supports sharing charts in repositories, allowing community collaboration and use of pre-built charts. An additional advantage of Helm is the ability to create reusable templates to facilitate the deployment of similar applications. This helps standardize and automate application deployment, saving time and effort.In summary, Helm is a powerful tool for package management and application deployment in the Kubernetes ecosystem. With Helm, you can create custom charts, easily install apps, manage multiple versions, and collaborate with the community to share and reuse charts. It is widely used to simplify the deployment of complex applications and provide a consistent Kubernetes experience.",
    quote:
    "Contact us to provision it in your environment",
  },
];

export default blogData;
